/* eslint-disable prettier/prettier */

import { Application } from "../types/Application";

import accelerator from "./accelerator";
import calculationEngine from "./calculation-engine";
import caseManagementSpa from "./case-management-spa";
import commonDataAdmin from "./common-data-admin";
import landing from "./landing";
import listOfValuesAdmin from "./list-of-values-admin";
import manualEntry from "./manual-entry";
import materialBalance from "./material-balance";
import mineMaintenancePortal from "./mine-maintenance-portal";
import potashReporting from "./potash-reporting";
import safeProductionCalendar from "./safe-production-calendar";
import shiftLogs from "./shift-logs";
import uiNavigation from "./ui-navigation";

export const applications: Record<string, Application> = {
  "accelerator": accelerator,
  "calculation-engine": calculationEngine,
  "case-management-spa": caseManagementSpa,
  "common-data-admin": commonDataAdmin,
  "landing": landing,
  "list-of-values-admin": listOfValuesAdmin,
  "manual-entry": manualEntry,
  "material-balance": materialBalance,
  "mine-maintenance-portal": mineMaintenancePortal,
  "potash-reporting": potashReporting,
  "safe-production-calendar": safeProductionCalendar,
  "shift-logs": shiftLogs,
  "ui-navigation": uiNavigation
};
