import {
  API_ACCELERATOR,
  API_CALCULATION_ENGINE,
  API_CASE_MANAGEMENT,
  API_COMMON_DATA,
  API_LIST_OF_VALUES,
  API_MANUAL_ENTRY,
  API_MATERIAL_BALANCE,
  API_MINE_MAINTENANCE_PORTAL,
  API_OLS,
  API_POTASH_REPORTING,
  API_SAFE_PRODUCTION_CALENDAR,
  API_SHIFT_LOGS,
  LEGACY_API_ACCELERATOR
} from "./env-api-urls";

type APIConfig = Record<
  | "accelerator"
  | "case-management"
  | "calculation-engine"
  | "common-data"
  | "legacy-accelerator"
  | "list-of-values"
  | "manual-entry"
  | "material-balance"
  | "mine-maintenance-portal"
  | "online-log-sheets"
  | "potash-reporting"
  | "safe-production-calendar"
  | "shift-logs",
  string
>;

type AmplifyAuthConfig = {
  adfsFederatedIdentityProviderId: string;
  region: string;
  userPoolWebClientId: string;
  userPoolId: string;
  oauth: {
    domain: string;
    scope: string[];
    redirectSignIn: string;
    redirectSignOut: string;
    responseType: string;
  };
};

type AmplifyConfig = {
  Auth: AmplifyAuthConfig;
};

type PowerBiConfig = {
  redirectUri: string;
  clientId: string;
  authority: string;
};
type SentryConfig = {
  dsn: string;
  sampleRate: string;
  replayErrorSampleRate: string;
};

/**
 * Which DB/API environment configuration the build is pointing at.
 *
 * If `development`, the build will be pointed at DEV apis.
 * If `uat`, the build will be pointed at UAT apis.
 * If `production`, the build will be pointed at PROD apis.
 * If `localdev`, the build will be pointed at DEV apis (defined in `.env.localdev`)
 *
 * This is not to be confused with `NODE_ENV` which is used
 * to determine the build mode (development build vs. production build).
 * */
type NutrienEnv = "localdev" | "development" | "uat" | "production";

type AppConfig = {
  environment: NutrienEnv;
  API: APIConfig;
  Amplify: AmplifyConfig;
  PowerBi: PowerBiConfig;
  Sentry: SentryConfig;
};

/**
 * This config object has all of the required environment variables.
 *
 * NOTE: In some contexts, environment variables on `process.env` must be accessed through dot notation
 * on the object, instead of bracket notation, which may lead to `undefined` values.
 *
 * @example
 * process.env.NODE_ENV // "development"
 * process.env['NODE_ENV'] // undefined
 */
export const config: AppConfig = {
  environment: process.env.NUTRIEN_ENV as NutrienEnv,
  API: {
    accelerator: API_ACCELERATOR,
    "calculation-engine": API_CALCULATION_ENGINE,
    "case-management": API_CASE_MANAGEMENT,
    "common-data": API_COMMON_DATA,
    "legacy-accelerator": LEGACY_API_ACCELERATOR,
    "list-of-values": API_LIST_OF_VALUES,
    "manual-entry": API_MANUAL_ENTRY,
    "material-balance": API_MATERIAL_BALANCE,
    "mine-maintenance-portal": API_MINE_MAINTENANCE_PORTAL,
    "online-log-sheets": API_OLS,
    "potash-reporting": API_POTASH_REPORTING,
    "safe-production-calendar": API_SAFE_PRODUCTION_CALENDAR,
    "shift-logs": API_SHIFT_LOGS
  },
  Amplify: {
    Auth: {
      adfsFederatedIdentityProviderId: process.env.ADFS_FEDERATED_IDENTITY_PROVIDER_ID,
      region: process.env.REGION,
      userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
      userPoolId: process.env.USER_POOL_ID,
      oauth: {
        domain: process.env.DOMAIN,
        scope: ["email", "openid", "profile", "transactions/get", "transactions/post"],
        redirectSignIn: process.env.REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REDIRECT_SIGN_OUT,
        responseType: "code"
      }
    }
  },
  PowerBi: {
    redirectUri: process.env.POWER_BI_REDIRECT_URI,
    clientId: process.env.POWER_BI_CLIENT_ID,
    authority: process.env.POWER_BI_AUTHORITY
  },
  Sentry: {
    dsn: process.env.SENTRY_DSN,
    replayErrorSampleRate: process.env.SENTRY_DSN_REPLAY_ERROR_SAMPLE_RATE,
    sampleRate: process.env.SENTRY_DSN_SAMPLE_RATE
  }
};
