export const API_ACCELERATOR = process.env.API_ACCELERATOR;
export const API_CALCULATION_ENGINE = process.env.API_CALCULATION_ENGINE;
export const API_CASE_MANAGEMENT = process.env.API_CASE_MANAGEMENT;
export const API_COMMON_DATA = process.env.API_COMMON_DATA;
export const API_LIST_OF_VALUES = process.env.API_LIST_OF_VALUES;
export const API_MANUAL_ENTRY = process.env.API_MANUAL_ENTRY;
export const API_MATERIAL_BALANCE = process.env.API_MATERIAL_BALANCE;
export const API_MINE_MAINTENANCE_PORTAL = process.env.API_MINE_MAINTENANCE_PORTAL;
export const API_OLS = process.env.API_OLS;
export const API_POTASH_REPORTING = process.env.API_POTASH_REPORTING;
export const API_SAFE_PRODUCTION_CALENDAR = process.env.API_SAFE_PRODUCTION_CALENDAR;
export const API_SHIFT_LOGS = process.env.API_SHIFT_LOGS;

export const LEGACY_API_ACCELERATOR = process.env.LEGACY_API_ACCELERATOR;
